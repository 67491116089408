<template>
  <!-- 运营外部班级 -->
  <div class="pageContol listWrap outClassList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >班级列表（河北）</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 8px">
            <div title="班级编号" class="searchboxItem ci-full">
              <span class="itemLabel">班级编号:</span>
              <el-input
                v-model="projectCode"
                type="text"
                clearable
                size="small"
                placeholder="请输入班级编码"
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                clearable
                size="small"
                placeholder="请输入班级名称"
              />
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-input
                v-model="sourceCompName"
                type="text"
                clearable
                size="small"
                placeholder="请输入机构名称"
              />
            </div>
            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel">状态:</span>
              <el-select
                size="small"
                v-model="classstatus"
                placeholder="请选择班级状态"
                clearable
              >
                <el-option
                  v-for="item in ClassstatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="开始时间" class="searchboxItem ci-full">
              <span class="listLabel">开始时间:</span>
              <el-date-picker
                class="listInput"
                clearable
                size="small"
                v-model="startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="结束时间" class="searchboxItem ci-full">
              <span class="listLabel">结束时间:</span>
              <el-date-picker
                class="listInput"
                clearable
                size="small"
                v-model="endTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="exportData"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              height="100%"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="班级编码"
                align="left"
                prop="projectCode"
                show-overflow-tooltip
                fixed
              />
              <el-table-column
                label="班级名称"
                align="left"
                prop="projectName"
                show-overflow-tooltip
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
              />
              <el-table-column
                label="开始时间"
                align="left"
                prop="startDate"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.startDate | momentDate
                }}</template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                align="left"
                prop="endDate"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.endDate | momentDate
                }}</template>
              </el-table-column>
              <el-table-column
                label="审核状态"
                align="left"
                prop="auditState"
                show-overflow-tooltip
                width="80"
              >
                <template slot-scope="scope">{{
                  $setDictionary("PROJECTAUDITSTATE", scope.row.auditState)
                }}</template>
              </el-table-column>
              <el-table-column
                label="是否允许打印档案"
                align="center"
                prop="endDate"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.allowPrint ? "是" : "否"
                }}</template>
              </el-table-column>
              <el-table-column
                label="推送时间"
                align="left"
                prop="auditState"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.createTime | moment
                }}</template>
              </el-table-column>
              <el-table-column
                  label="删除状态"
                  align="left"
                  prop="deleted"
                  show-overflow-tooltip
                  width="80"
              >
                <template slot-scope="scope">{{
                    scope.row.deleted?'已删除':'正常'
                  }}</template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="160px">
                <div slot-scope="scope">
                  <el-button
                    style="padding: 0 5px"
                    type="text"
                    size="mini"
                    @click="pushFile(scope.row)"
                    :disabled="!(scope.row.allowPrint && scope.row.auditState == '30')"
                    >推送档案</el-button
                  >

                  <el-button
                      style="padding: 0 5px"
                      type="text"
                      size="mini"
                      :disabled="scope.row.deleted||scope.row.auditState == '30'"
                      @click="handleDel(scope.row.projectId)"
                  >删除</el-button
                  >
                  <el-button
                    style="padding: 0 5px"
                    type="text"
                    size="mini"
                    @click="seeDetails(scope.row)"
                    >详情</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "operate/outClassList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      projectName: "",
      outplant: "",
      deletedstate: "true",
      userstate: "",
      projectCode: "",
      sourceCompName: "",
      startTime: "",
      endTime: "",
      classstatus: "",
      ClassstatusList: [],
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getclassstatusList();
  },
  methods: {
    // 删除
    handleDel(projectId) {
      this.$confirm("你确认要删除此班级信息吗？删除后将不可恢复！", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
          .then(() => {
            this.$post("/biz/canton/delete", { projectId }).then(
                res => {
                  if (res.status == "0") {
                    this.$message({
                      type: "success",
                      message: "删除成功!"
                    });
                    this.getData(-1)
                  }
                }
            );
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
    },
    // 导出
    exportData() {
      const params = {
        key: "20",
      };
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.sourceCompName) {
        params.sourceCompName = this.sourceCompName;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.classstatus) {
        params.auditState = this.classstatus;
      }
      if (this.startTime && this.startTime.length > 0) {
        params.startDateStart = this.startTime[0];
        params.startDateEnd = this.startTime[1];
      }
      if (this.endTime && this.endTime.length > 0) {
        params.endDateStart = this.endTime[0];
        params.endDateEnd = this.endTime[1];
      }
      this.$post("/biz/canton/projectExport", params).then((res) => {
        if (res.status == "0") {
          let list = res.data;
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              console.log(item.fileName);
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(re.message);
        }
      });
    },
    // 详情
    seeDetails(row) {
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("compId", row.compId);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("projectSource", row.projectSource);
      console.log(row.projectSource);
      this.$router.push({
        path: "/web/classDetailHB",
        query: {
          projectId: row.projectId,
          stu: "view",
          hasGovRollCall: JSON.stringify(row.hasGovRollCall) || "false",
          hasRollCall: JSON.stringify(row.hasRollCall) || "false",
          projectSource: row.projectSource,
        },
      });
    },
    // 推送档案
    pushFile(row) {
      this.$post("/biz/guangzhou/admin/pushArchives", {
        key: "20",
        projectId: row.projectId,
      }).then((res) => {
        if(res.status == '0'){
          this.$message({
            message: "推送成功",
            type: "success",
          });
        }
      });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        key: "20",
      };
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.sourceCompName) {
        params.sourceCompName = this.sourceCompName;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.classstatus) {
        params.auditState = this.classstatus;
      }
      if (this.startTime && this.startTime.length > 0) {
        params.startDateStart = this.startTime[0];
        params.startDateEnd = this.startTime[1];
      }
      if (this.endTime && this.endTime.length > 0) {
        params.endDateStart = this.endTime[0];
        params.endDateEnd = this.endTime[1];
      }
      this.doFetch({
        url: "/biz/canton/projectPageList",
        params,
        pageNum,
      });
    },
    // 获取字典
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTAUDITSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        if (key != "45") {
          this.ClassstatusList.push({
            value: key,
            label: classstatuslist[key],
          });
        }
      }
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less" scoped>
.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>